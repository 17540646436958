import clsx from 'clsx';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { initialValues, bankSchema, BankAccountType } from './bank.type';
import { useNavigate } from 'react-router-dom';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import 'react-phone-input-2/lib/style.css';
import { updateBankAccount } from '../../redux/auth/authThunk';
import { useAppDispatch } from '../../redux/store';
import { toast } from 'react-toastify';
import { BANK_INFO, RoutePath } from '../../constants/constant';
export const BankAccount: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const initValue = () => {
    const bankAccount = JSON.parse(localStorage.getItem(BANK_INFO) || '');
    const { accountNumber, accountName, bankName, bankAddress, bankSwiftCode } = bankAccount;
    return{
      account_no: accountNumber || '',
      account_name: accountName || '',
      bank_name: bankName || '',
      bank_address: bankAddress || '',
      swift_code: bankSwiftCode || '',
    }
  }
  const onSubmit = (values: BankAccountType, actions: any) => {
    dispatch(
      updateBankAccount({
        account_no: values.account_no,
        account_name: values.account_name,
        bank_name: values.bank_name,
        bank_address: values.bank_address,
        swift_code: values.swift_code,
      }),
    )
      .unwrap()
      .then((response) => {
        const { message } = response.data;
        toast.success(message);
        navigate(RoutePath.PROFILE);
      });
  };

  return (
    <div className="w-full">
      <div className="lg:col-span-3 h-full items-center flex mt-20 lg:mt-0 ">
        <div className="w-full ">
          <div className="md:w-1/3 mx-auto p-10 rounded-xl">
            <div className="items-center">
              <span className="text-center uppercase text-2xl font-semibold block mb-5">
                Update Bank Account
              </span>
            </div>

            <Formik
              initialValues={initValue()}
              validationSchema={bankSchema}
              onSubmit={onSubmit}
            >
              {({
                handleSubmit,
                isSubmitting,
                values,
                handleBlur,
                handleChange,
                errors,
                touched,
              }) => {
                // @ts-ignore
                return (
                  <div>
                    <div className="mt-10">
                      <label className="block mb-1">Account No</label>
                      <div className="relative">
                        <input
                          name="account_no"
                          id="account_no"
                          value={values.account_no}
                          onBlur={handleBlur('account_no')}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                              handleSubmit();
                            }
                          }}
                          onChange={handleChange('account_no')}
                          className="outline-none px-5 py-3 rounded-md border border-gray-200 w-full placeholder-gray-400 text-gray-600"
                        />
                        {touched.account_no && errors.account_no && (
                          <div className="text-red-500 text-sm mt-1">
                            {errors.account_no}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mt-10">
                      <label className="block mb-1">Account Name</label>
                      <div className="relative">
                        <input
                          name="account_name"
                          id="account_name"
                          value={values.account_name}
                          onBlur={handleBlur('account_name')}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                              handleSubmit();
                            }
                          }}
                          onChange={handleChange('account_name')}
                          className="outline-none px-5 py-3 rounded-md border border-gray-200 w-full placeholder-gray-400 text-gray-600"
                        />
                        {touched.account_name && errors.account_name && (
                          <div className="text-red-500 text-sm mt-1">
                            {errors.account_name}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mt-10">
                      <label className="block mb-1">Bank Name</label>
                      <div className="relative">
                        <input
                          name="bank_name"
                          id="bank_name"
                          value={values.bank_name}
                          onBlur={handleBlur('bank_name')}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                              handleSubmit();
                            }
                          }}
                          onChange={handleChange('bank_name')}
                          className="outline-none px-5 py-3 rounded-md border border-gray-200 w-full placeholder-gray-400 text-gray-600"
                        />
                        {touched.bank_name && errors.bank_name && (
                          <div className="text-red-500 text-sm mt-1">
                            {errors.bank_name}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mt-10">
                      <label className="block mb-1">Bank Address</label>
                      <div className="relative">
                        <input
                          name="bank_address"
                          id="bank_address"
                          value={values.bank_address}
                          onBlur={handleBlur('bank_address')}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                              handleSubmit();
                            }
                          }}
                          onChange={handleChange('bank_address')}
                          className="outline-none px-5 py-3 rounded-md border border-gray-200 w-full placeholder-gray-400 text-gray-600"
                        />
                        {touched.bank_address && errors.bank_address && (
                          <div className="text-red-500 text-sm mt-1">
                            {errors.bank_address}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mt-10">
                      <label className="block mb-1">Swift Code</label>
                      <div className="relative">
                        <input
                          name="swift_code"
                          id="swift_code"
                          value={values.swift_code}
                          onBlur={handleBlur('swift_code')}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                              handleSubmit();
                            }
                          }}
                          onChange={handleChange('swift_code')}
                          className="outline-none px-5 py-3 rounded-md border border-gray-200 w-full placeholder-gray-400 text-gray-600"
                        />
                        {touched.swift_code && errors.swift_code && (
                          <div className="text-red-500 text-sm mt-1">
                            {errors.swift_code}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="mt-12">
                      <button
                        type="submit"
                        onClick={() => handleSubmit()}
                        className={clsx({
                          'px-4 py-3 rounded-md text-gray-50 w-full': true,
                          'bg-gray-400': isSubmitting,
                          'bg-blue-700': !isSubmitting,
                        })}
                      >
                        Update
                      </button>
                    </div>

                  </div>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};
