import React, {useEffect, useRef, useState} from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { useAppDispatch } from '../../redux/store';
import {json, useNavigate} from 'react-router-dom';
import {Button, Modal, Select, Table} from 'antd';
import { ColumnsType } from 'antd/es/table';
import {useFormik} from 'formik';
import { AutoComplete } from 'antd';
import {toast} from "react-toastify";
import clsx from "clsx";
import {
  autoCompleteUser,
  revenueSchema,
  RevenueShareDataType,
  RevenueShareResponseType
} from "./revenue.type";
import {
  confirmMyRevenueShare, createOrUpdateRevenueShare,
  getAllMyRevenueShare
} from "../../redux/revenue/revenueThunk";
import {USER_INFO} from "../../constants/constant";
import {getMemberByName, getUserAutoComplete} from "../../redux/home/homeThunk";
const countryCodes = require('country-codes-list')

export const RevenueShare: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [revenueShare, setRevenueShare] = useState();
  const [referUrl, setReferUrl] = useState('');
  const [options, setOptions] = useState<autoCompleteUser[]>([]);
  const [countries, setCountries] = useState<{value: string; label: string}[]>([]);
  const [editedObject, setEditedObject] = useState<RevenueShareResponseType>();
  const myCountryCodesObject = countryCodes.customList('countryCode', '{countryNameEn}');
  const userInfo = JSON.parse(localStorage.getItem(USER_INFO) || '{}');

  const handleSearchUser = (value: string) => {
    dispatch(getMemberByName({keyword: value}))
      .unwrap()
      .then((response) => {
        setOptions(response.data.data)
      });
  };
  const getAllMyRevenueShareData = () => {
    dispatch(getAllMyRevenueShare())
      .unwrap()
      .then((response) => {
        setRevenueShare(response.data.data);
        console.log(response.data.referUrl);
        setReferUrl(response.data.referUrl);
      });
  };

  const getCountries = () => {
    const data = Object.entries(myCountryCodesObject).map(entry => {
      return {
        value: entry[0].toLowerCase(),
        label: entry[1],
      }
    });
    // @ts-ignore
    setCountries(data);
  }

  useEffect(() => {
    void getAllMyRevenueShareData();
    void getCountries();
  }, [dispatch]);

  const validateForm = () => {
    const totalPercent = Number(formik.values.licensee_fee);
    if(totalPercent > 100 || totalPercent < 0) {
      formik.setErrors({licensee_fee:"invalid licensee fee"})
      return false;
    }
    return  formik.isValid;
  }

  const submitForm = () => {
    try{
      if(!validateForm() || (!formik.values.id && editedObject)){
        setLoading(false);
        toast.error('Invalid Data')
        return;
      }
      setLoading(true);
      if(formik.values.id && editedObject && !editedObject.userConfirmed) { ///this is update action
        dispatch(
          confirmMyRevenueShare({revenue_share_id: formik.values.id, is_accepted: formik.values.is_accepted}),
        )
          .unwrap()
          .then((response) => {
            setLoading(false);
            setIsModalOpen(false);
            window.location.reload();
            toast.info(response.data.message);
          })
      }else{
        dispatch(
          createOrUpdateRevenueShare(formik.values),
        )
          .unwrap()
          .then((response) => {
            setLoading(false);
            setIsModalOpen(false);
            toast.info(response.data.message);
          })
      }
    }catch (ex){
      console.log(ex)
    }finally {
      setLoading(false);
    }
  };

  const onViewRevenueShare = (record: RevenueShareResponseType) => {
    console.log(record);
    setEditedObject(record);
    setIsModalOpen(true);
  }

  const onConfirmClick = (isAccepted: boolean) => {
    formik.setFieldValue('is_accepted', isAccepted);
    setOpen(true);
  }

  const handleOk = () => {
    setOpen(false);
    if(!validateForm()){
      setLoading(false);
      toast.error('Invalid Data')
      return;
    }
    setLoading(true);
    formik.submitForm().then();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const columns: ColumnsType<RevenueShareResponseType> = [
    {
      title: 'Licensee Name',
      dataIndex: 'license_account',
      key: 'license_account',
      render: (_, record) => (
        <span>{record.licenseeId?.userName}</span>
      ),
    },
    {
      title: 'Licensee Account',
      dataIndex: 'license_account',
      key: 'license_account',
      render: (_, record) => (
        <span>{record.licenseeId?.userPublicAddress}</span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'revenue_status',
      key: 'revenue_status',
      render: (_, record) => (
        <span>{record.status} {record.licenseeFeeNewValue? `(To change Percentage to ${record.licenseeFeeNewValue}%)` : ''}</span>
      ),
    },
    {
      title: 'Percentage(%)',
      dataIndex: 'revenue_percentage',
      key: 'revenue_percentage',
      render: (_, record) => (
        <span>{record.licenseeFee}</span>
      ),
    },
    {
      title: 'Territory code',
      dataIndex: 'territory',
      key: 'territory',
      render: (_, record) => {
        const countryCode = record.countryCode?.split(',');
        const countries: string[] = [];
        countryCode.forEach(item => {
          const value = myCountryCodesObject[item.toUpperCase()];
          if(value) countries.push(value)
        })
        console.log(myCountryCodesObject)
        return <span>{countries.join(',')}</span>
      },
    },
    {
      title: 'Created At',
      key: 'created_at',
      dataIndex: 'created_at',
      render: (_, record) => (
        <span>{record.createdAt.toString()}</span>
      ),
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: 120,
      render: (_, record) => {
        const isConfirmed = record.userConfirmed;
        const isOwner = record.userId.toString() === userInfo._id.toString();
        const button = [];
        if(isOwner) {
          button.push(
            <button
              type="submit"
              className={clsx(
                "py-2 px-4 mr-3 rounded-md border-2 text-white", "bg-blue-600"
              )}
              onClick={() => onViewRevenueShare(record)}
            >
              Update
            </button>
          )
        }else{
          button.push(
            <button
              type="submit"
              className={clsx(
                "py-2 px-4 mr-3 rounded-md border-2 text-white", "bg-blue-600"
              )}
              onClick={() => onViewRevenueShare(record)}
            >
              {isConfirmed? "View" : "Confirm"}
            </button>
          )
        }
        return (<div className={'flex flex-row items-center'}>
          {button}
        </div>);
      },
    },
  ];

  const formik = useFormik<RevenueShareDataType>({
    initialValues: {
      id: editedObject?._id,
      licensee_email: editedObject?.licenseeId?.userEmail || '',
      licensee_user_name: editedObject?.licenseeId?.userName || '',
      licensee_id: editedObject?.licenseeId?._id || '',
      licensee_fee: editedObject?.licenseeFee || editedObject?.licenseeFeeNewValue || 0,
      licensee_account_number: editedObject?.licenseeId.userPublicAddress || '',
      country_code: editedObject?.countryCode || '',
      is_accepted: false,
    },
    enableReinitialize: true,
    validationSchema: revenueSchema,
    onSubmit: submitForm,
  });

  const footer = () => {
    let buttons = [];
    if(editedObject){
      buttons.push(
        <Button style={{float: 'left'}} key="back" onClick={()=>{setIsModalOpen(loading)}}>
          <a href={referUrl + editedObject?.appId} target="#">View Smart Contract</a>
        </Button>
      )
    }
    buttons.push(
      <Button key="back" disabled={loading} onClick={()=>{
        setIsModalOpen(loading)
      }}>
        Close
      </Button>
    )
    if(!editedObject){
      buttons.push(
        <Button
          key="link"
          href="#"
          type="primary"
          loading={loading}
          onClick={() => {
            if (!validateForm()) {
              setLoading(false);
              toast.error('Invalid Data')
              return;
            }
            setLoading(true);
            formik.submitForm().then();
          }}
        >
          {loading ? "Processing" : "Submit"}
        </Button>
      )
    }
    else if(editedObject){
      const isConfirmed = editedObject.userConfirmed;
      console.log('isConfirmed',editedObject);
      const isOwner = editedObject.userId.toString() === userInfo._id.toString();
      if(isOwner){
        buttons.push(
          <Button
            key="link"
            href="#"
            type="primary"
            loading={loading}
            onClick={() => {
              if (!validateForm()) {
                setLoading(false);
                toast.error('Invalid Data')
                return;
              }
              setLoading(true);
              formik.submitForm().then();
            }}
          >
            {loading ? "Processing" : "Update"}
          </Button>
        )
      }else {
        if (!isConfirmed) {
          buttons.push(
            <Button
              key="link"
              href="#"
              type="primary"
              loading={loading}
              onClick={() => {
                onConfirmClick(true);
              }}
            >
              Accept
            </Button>
          );
          buttons.push(
            <Button
              key="link"
              danger
              href="#"
              type="primary"
              loading={loading}
              onClick={() => {
                onConfirmClick(false);
              }}
            >
              Decline
            </Button>
          )
        }
      }
      return buttons;
    }
    return buttons;
  }


  const renderAddNewLicensingModal = () => {
    const isOwner = editedObject && editedObject.userId.toString() === userInfo._id.toString();
    return (
      <Modal
        width={800}
        title="Add New Revenue Sharing Smart Contract"
        open={isModalOpen}
        onCancel={()=>{setIsModalOpen(false)}}
        footer={footer()}
      >
        <form>
          <div className="mt-5">
            <div className={'flex flex-row justify-between'}>
              <div style={{width:'50%', marginRight:5}}>
                <label className="block font-bold">Licensee account</label>
                <AutoComplete
                  id="licensee_user_name"
                  bordered={false}
                  style={{width: '100%', height: '', padding: 8}}
                  placeholder="licensee_user_name"
                  options={options}
                  onSearch={handleSearchUser}
                  autoFocus
                  disabled={!!editedObject}
                  value={formik.values.licensee_user_name}
                  onBlur={formik.handleBlur('licensee_user_name')}
                  onChange={
                    formik.handleChange('licensee_user_name')
                  }
                  onSelect={(item: any, option) =>{
                    if (!option) {
                      return;
                    }
                    let {id, userEmail, userName, userPublicAddress} = option;
                    formik.setFieldValue('licensee_user_name', userName);
                    formik.setFieldValue('licensee_id', id);
                    formik.setFieldValue('licensee_email', userEmail);
                    formik.setFieldValue('licensee_account_number', userPublicAddress);
                  }}
                  className={clsx(
                    "outline-none px-5 rounded-md border placeholder-gray-400 text-gray-600",
                    formik.errors.licensee_user_name? "border-red-400": "border-gray-200"
                  )}
                />
              </div>
              <div style={{width:'50%',marginRight:5, marginTop:2}}>
                <input
                  name="licensee_email"
                  id="licensee_email"
                  autoFocus
                  disabled={true}
                  placeholder="email"
                  value={formik.values.licensee_email}
                  onBlur={formik.handleBlur('licensee_email')}
                  onChange={formik.handleChange('licensee_email')}
                  className={clsx(
                    "outline-none w-full mt-5 px-5 py-3 rounded-md border placeholder-gray-400 text-gray-600",
                    formik.errors.licensee_email? "border-red-400": "border-gray-200"
                  )}
                />
              </div>
              <div style={{width:'100%',marginRight:5, marginTop:2}}>
                <input
                  name="licensee_account_number"
                  id="licensee_account_number"
                  autoFocus
                  disabled={true}
                  placeholder="Account number"
                  value={formik.values.licensee_account_number}
                  onBlur={formik.handleBlur('licensee_account_number')}
                  onChange={formik.handleChange('licensee_account_number')}
                  className={clsx(
                    "outline-none w-full mt-5 px-5 py-3 rounded-md border placeholder-gray-400 text-gray-600",
                    formik.errors.licensee_account_number? "border-red-400": "border-gray-200"
                  )}
                />
              </div>
              <div style={{width:'20%'}}>
                <label className="block font-bold">Percentage(%)</label>
                <input
                  name="licensee_fee"
                  id="licensee_fee"
                  autoFocus
                  disabled={!isOwner || (editedObject && !editedObject.userConfirmed)}
                  placeholder="10"
                  value={formik.values.licensee_fee}
                  onBlur={formik.handleBlur('licensee_fee')}
                  onChange={formik.handleChange('licensee_fee')}
                  className={clsx(
                    "outline-none w-full px-5 py-3 rounded-md border placeholder-gray-400 text-gray-600",
                    formik.errors.licensee_fee? "border-red-400": "border-gray-200"
                  )}
                />
              </div>
            </div>
            <div className={'flex flex-row justify-between mt-5'}>
              <div style={{width:'60%', marginRight:5}}>
                <label className="block font-bold" htmlFor="password">
                  Territories
                </label>
                <Select
                  className={clsx(
                    "outline-none px-5 rounded-md border placeholder-gray-400 text-gray-600",
                    formik.errors.country_code? "border-red-400": "border-gray-200"
                  )}
                  key="value"
                  mode="multiple"
                  placeholder="Please select"
                  value={ formik.values.country_code.length > 0? formik.values.country_code.split(','): null}
                  onChange={(val) => formik.setFieldValue('country_code', val.join(','))}
                  options={countries}
                  style={{width: '100%', height: '', padding: 7}}
                  bordered={false}
                  disabled={!!editedObject}
                />
              </div>
            </div>
            <div className={'flex flex-row justify-between mt-5'}>
              <div style={{width:'60%', marginRight:5}}>
                <label className="block font-bold" htmlFor="password">
                  {formik.errors? JSON.stringify(formik.errors) : ''}
                </label>
              </div>
            </div>
          </div>
          </form>
      </Modal>
    );
  };

  return (
    <div className="w-4/5 h-screen mt-10 ml-5 flex flex-col overflow-hidden">
      <div className="flex flex-row ml-0 justify-between mb-10">
        <h1 className="text-2xl text-blue-900">REVENUE SHARE</h1>
        <br/>
        <button
          type="submit"
          onClick={() => {
            setEditedObject(undefined);
            setIsModalOpen(true);
          }}
          className={'p-2 rounded-md border-2 border-blue-600 text-blue-800'}
        >
          New Revenue Sharing Smart Contract
        </button>
      </div>
      <Table rowKey="_id" columns={columns} dataSource={revenueShare}  scroll={{ x: '100%', y: 800 }}/>
      {renderAddNewLicensingModal()}
      <Modal
        title="Confirm Revenues Share"
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Close
          </Button>,
          <Button
            key="link"
            href="#"
            type="primary"
            loading={loading} onClick={handleOk}>
            Submit
          </Button>
        ]}
      >
        <p>Are you sure?</p>
      </Modal>
    </div>
  );
};
