'use strict';

let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';

// Use a lookup table to find the index.
let lookup = new Uint8Array(256);
for (let i = 0; i < chars.length; i++) {
	lookup[chars.charCodeAt(i)] = i;
}

let encode = function (arraybuffer: any) {
	let bytes = new Uint8Array(arraybuffer),
		i,
		len = bytes.length,
		base64url = '';

	for (i = 0; i < len; i += 3) {
		base64url += chars[bytes[i] >> 2];
		base64url += chars[((bytes[i] & 3) << 4) | (bytes[i + 1] >> 4)];
		base64url += chars[((bytes[i + 1] & 15) << 2) | (bytes[i + 2] >> 6)];
		base64url += chars[bytes[i + 2] & 63];
	}

	if (len % 3 === 2) {
		base64url = base64url.substring(0, base64url.length - 1);
	} else if (len % 3 === 1) {
		base64url = base64url.substring(0, base64url.length - 2);
	}

	return base64url;
};

let decode = function (base64string: string) {
	let bufferLength = base64string.length * 0.75,
		len = base64string.length,
		i,
		p = 0,
		encoded1,
		encoded2,
		encoded3,
		encoded4;

	let bytes = new Uint8Array(bufferLength);

	for (i = 0; i < len; i += 4) {
		encoded1 = lookup[base64string.charCodeAt(i)];
		encoded2 = lookup[base64string.charCodeAt(i + 1)];
		encoded3 = lookup[base64string.charCodeAt(i + 2)];
		encoded4 = lookup[base64string.charCodeAt(i + 3)];

		bytes[p++] = (encoded1 << 2) | (encoded2 >> 4);
		bytes[p++] = ((encoded2 & 15) << 4) | (encoded3 >> 2);
		bytes[p++] = ((encoded3 & 3) << 6) | (encoded4 & 63);
	}

	return bytes;
};

let toString =  function Utf8ArrayToStr(array: Uint8Array) {
  let out, i, len, c;
  let char2, char3;

  out = "";
  len = array.length;
  i = 0;
  while(i < len) {
    c = array[i++];
    switch(c >> 4)
    {
      case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
      // 0xxxxxxx
      out += String.fromCharCode(c);
      break;
      case 12: case 13:
      // 110x xxxx   10xx xxxx
      char2 = array[i++];
      out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
      break;
      case 14:
        // 1110 xxxx  10xx xxxx  10xx xxxx
        char2 = array[i++];
        char3 = array[i++];
        out += String.fromCharCode(((c & 0x0F) << 12) |
          ((char2 & 0x3F) << 6) |
          ((char3 & 0x3F) << 0));
        break;
    }
  }

  return out;
}

let methods = {
	decode: decode,
	encode: encode,
  toString: toString,
};

export default methods;
