import React, { useEffect, useState } from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { getBalance } from '../../redux/home/homeThunk';
import { useAppDispatch } from '../../redux/store';
import moment from 'moment/moment';



export const Home: React.FC = () => {
  const dispatch = useAppDispatch();
  const [balance, setBalance] = useState<number>(0.0);
  const [received, setReceived] = useState<number>(0.0);
  const today = moment().format("DD MMM YYYY");

  useEffect(() => {
    dispatch(getBalance())
      .unwrap()
      .then((response) => {
        setBalance(response.data.data.payable);
        setReceived(response.data.data.received);
      });
  }, []);

  return (
    <div className="w-4/5 h-screen mt-10 ml-5 flex flex-col overflow-hidden">
      <div className="col-span-0 lg:col-span-3 h-1/2 items-center flex mt-0 lg:mt-0 ">
        <div className="w-full flex flex-row">
          <div className="md:w-1/4 mx-auto p-10  border rounded-xl bg-blue-500">
            <div className="items-center justify-center">
              <span className="text-center text-white uppercase text-2xl font-semibold block">
                Royalty Accrual / Payable:
              </span>
              <span className="text-center text-white uppercase text-xl font-semibold block">
                {(balance/1000).toFixed(2)} EBTA as {today}
              </span>
            </div>
          </div>

          <div className="md:w-1/4 mx-auto p-10  border rounded-xl bg-blue-500">
            <div className="items-center justify-center">
              <span className="text-center text-white uppercase text-2xl font-semibold block">
                Payment Received:
              </span>
              <span className="text-center text-white uppercase text-xl font-semibold block">
                {received} SGD as {today}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
