import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { initialValues, loginSchema, LoginType } from './login.type';
import { useAppDispatch } from '../../redux/store';
import {signIn, validatePassKey} from '../../redux/auth/authThunk';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { ACCESS_TOKEN, RoutePath } from '../../constants/constant';
import {useNavigate} from "react-router-dom";
import getPasskeyCredential from "../../utils/passkey/authenticate/getPasskeyCredential";
import {publicKeyCredentialToJSON} from "../../utils/passkey/authenticate/publicKeyCredentialToJSON";

export const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const passwordRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const authed = !!localStorage.getItem(ACCESS_TOKEN);
    if(authed){
      navigate(RoutePath.HOME);
    }
  },[])

  const onSubmit = (values: LoginType, actions: any) => {
    dispatch(
      signIn({
        username: values.username,
        password: values.password,
      }),
    )
      .unwrap()
      .then(async (response) => {
        if(response.data.data.jwtToken){
          navigate(RoutePath.HOME);
        }else {
          //validate passkey
          const credential = await performLogin(response.data.data.challenge);
          if (credential !== null) {
            dispatch(
              validatePassKey({
                credential: publicKeyCredentialToJSON(credential),
                user_name: values.username,
                user_id: response.data.data.userId
              }),
            )
              .unwrap()
              .then(async (response) => {
                navigate(RoutePath.HOME)
              })
          } else {
            toast(" signIn ❌ Failed to perform Login as credential does not exist.");
          }
        }
      })
  };

  const performLogin = async (challenge: string) => {
    console.log("⚈ ⚈ ⚈ performLogin ⚈ ⚈ ⚈");
    try {
      const credential = await getPasskeyCredential(challenge);
      console.log(" performLogin ✅ credential : ", credential);
      return credential;
    } catch (error) {
      console.log(
        "performLogin ❌  Failed to get credential with error : ",
        error
      );
      return null;
    }
  };

  return (
    <div className="w-full">
      <div className="col-span-5 lg:col-span-3 h-full items-center flex mt-20 lg:mt-0 ">
        <div className="w-full">
          <div className="md:w-1/3 mx-auto p-10  border rounded-xl">
            <div className="grid items-center justify-center">
              <img className="w-20" src={logo} alt="background" />
              <span className="text-center uppercase text-2xl font-semibold block mt-10">
                LOGIN
              </span>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={loginSchema}
              onSubmit={onSubmit}
            >
              {({
                handleSubmit,
                isSubmitting,
                values,
                handleBlur,
                handleChange,
                errors,
                touched,
              }) => {
                return (
                  <div className="mt-14">
                    <div>
                      <label className="block mb-1" htmlFor="username">
                        User ID
                      </label>
                      <input
                        name="username"
                        id="username"
                        autoFocus
                        value={values.username}
                        onBlur={handleBlur('username')}
                        onChange={handleChange('username')}
                        className="outline-none px-5 py-3 rounded-md border border-gray-200 w-full placeholder-gray-400 text-gray-600"
                      />
                        <div className="text-red-500 text-sm mt-1">
                          {errors.username}
                        </div>
                    </div>

                    <div className="mt-10">
                      <label className="block mb-1" htmlFor="password">
                        Password
                      </label>
                      <div className="relative">
                        <input
                          name="password"
                          id="password"
                          ref={passwordRef}
                          type={showPassword ? 'text' : 'password'}
                          value={values.password}
                          onBlur={handleBlur('password')}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                              handleSubmit();
                            }
                          }}
                          onChange={handleChange('password')}
                          className="outline-none px-5 py-3 rounded-md border border-gray-200 w-full placeholder-gray-400 text-gray-600"
                        />
                        <FontAwesomeIcon
                          onClick={() => setShowPassword(!showPassword)}
                          icon={showPassword ? faEye : faEyeSlash}
                          className="absolute top-4 right-4 text-gray-400 cursor-pointer"
                        />
                      </div>
                      {touched.password && errors.password && (
                        <div className="text-red-500 text-sm mt-1">
                          {errors.password}
                        </div>
                      )}
                    </div>

                    <div className="mt-4 text-center text-blue-500">
                    <a href={'/forgot-password'}>Forgot password? </a>
                    </div>
                    <div className="mt-8">
                      <button
                        type="submit"
                        // disabled={isSubmitting}
                        onClick={() => handleSubmit()}
                        className={clsx({
                          'px-4 py-3 rounded-md text-gray-50 w-full': true,
                          'bg-gray-400': isSubmitting,
                          'bg-blue-700': !isSubmitting,
                        })}
                      >
                        Login
                      </button>
                    </div>

                    <div className="mt-4 text-center">
                      <span className='pt-2'> Don't have account? {' '}
                        <a className='underline' href='/sign-up'>Sign up</a>
                      </span>
                    </div>
                  </div>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};
