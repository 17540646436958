import React, { useEffect, useState } from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import {useLocation, useNavigate} from 'react-router-dom';
import {DatePicker, Space, Table, Tag} from 'antd';
import {ColumnsType} from "antd/es/table";
import {AccrualDetailType} from "./accrualDetail.type";
import {getAccrualDetailData} from "../../redux/report/reportThunk";
import {RoutePath, USER_INFO} from "../../constants/constant";

export const AccrualDetail: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [accrualDetailData, setAccrualDetailData] = useState([]);
  let userInfo = localStorage.getItem(USER_INFO)? JSON.parse(localStorage.getItem(USER_INFO) || '{}') : null;
  const location = useLocation().state;
  if(!location || !userInfo){
    navigate('/')
  }
  let { accrual } = location;
  if(!accrual){
    navigate('/')
  }

  const getAccrualDetailReportData = () => {
    dispatch(getAccrualDetailData({accrualId: accrual._id}))
      .unwrap()
      .then((response) => {
        setAccrualDetailData(response.data.data);
      });
  };
  useEffect(() => {
    void getAccrualDetailReportData();
  }, [accrual]);

  const columns: ColumnsType<AccrualDetailType> = [
    {
      title: 'Subscription ID',
      dataIndex: 'fromSubscriberId',
      key: 'fromSubscriberId',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Play count',
      dataIndex: 'playCount',
      key: 'playCount',
      render: (_, record) => (
        <span>{record.playCount}</span>
      ),
    },
    {
      title: 'Amount (EBTA)',
      dataIndex: 'amount',
      key: 'amount',
      render: (_, record) => (
        <span>{record.amount > 0? (record.amount/1000).toFixed(2) :''}</span>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      render: (_, record) => (
        <button
          type="submit"
          onClick={() => navigate(RoutePath.ACCRUAL_DETAIL_BY_SUBSCRIBER, {
            state:{
              accrualDetail: record,
              accrual: accrual
            }
          })}
          className={
            'py-2 px-4 rounded-md bg-blue-600 border-2 border-blue-600 text-white'
          }
        >
          View
        </button>
      ),
    },
  ]
  return (
    <div className="w-4/5 h-screen mt-10 ml-5 flex flex-col overflow-hidden">
      <div className="flex flex-row my-10 lg:mt-0">
        <div className="flex flex-row w-1/2 hidden">
          <DatePicker picker='date' onChange={(value)=> console.log(value)} />
          <DatePicker className={'ml-5'} picker='date' onChange={(value)=> console.log(value)} />
          <button
            type="submit"
            // onClick={() => downloadKeystore()}
            className={
              'p-2 rounded-md border-2 border-blue-600 text-blue-800'
            }
          >
            Get Report
          </button>
        </div>
      </div>
      <div className={'flex flex-col mb-5 text-blue-800'}>
        <label>Accrual Transaction: <a target="_blank" href={`${process.env.REACT_APP_ALGORAND_EXPOLER_URL}/tx/${accrual.txId}`}>{accrual.txId}</a></label>
        <label>Amount: {accrual.debits > 0? (accrual.debits/1000).toFixed(2) :''} EBTA</label>
      </div>
      <Table columns={columns} dataSource={accrualDetailData} />
    </div>
  );
};
