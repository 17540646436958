import { createAsyncThunk } from '@reduxjs/toolkit';
import * as types from '../types';
import axiosClient from '../../apis/axiosClient';
import store from '../store';
import { appActions } from '../app/appSlice';
import { API } from '../../apis/api';
import {RevenueShareDataType} from "../../pages/my_revenue_share/revenue.type";

export const getAllMyRevenueShare = createAsyncThunk(
  types.GET_ALL_MY_REVENUE_SHARE,
  async () => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .get(API.GET_ALL_REVENUE_SHARE)
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);

export const confirmMyRevenueShare = createAsyncThunk(
  types.CONFIRM_REVENUE_SHARE,
  async (request: {revenue_share_id: string, is_accepted: boolean}) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.POST_ACCEPT_OR_DECLINE_REVENUE_SHARE,{
        revenue_share_id: request.revenue_share_id,
        is_accepted: request.is_accepted
      })
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);

export const createOrUpdateRevenueShare = createAsyncThunk(
  types.CREATE_OR_UPDATE_REVENUE_SHARE,
  async (request: RevenueShareDataType) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.CREATE_OR_UPDATE_REVENUE_SHARE, request)
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);




