import React, {useEffect, useState} from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { Col, Row } from 'antd';
import fileDownload from 'js-file-download';
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {initData, signUp} from "../../redux/auth/authThunk";
import { toast } from 'react-toastify';
import {useLocation, useNavigate} from "react-router-dom";
import {ACCESS_TOKEN, RoutePath} from "../../constants/constant";
import CreatePassKeyCredential from "../../utils/passkey/register/createPasskeyCredential";
import {publicKeyCredentialToJSON} from "../../utils/passkey/authenticate/publicKeyCredentialToJSON";

export const PassPhrase: React.FC = () => {
    const dispatch = useAppDispatch();
    const { challenge, challengeRestore, challengeId } = useAppSelector((state)=>state.auth);
    const [isContinue, setIsContinue] = useState<boolean>(false);
    const {state} = useLocation();
    const navigate = useNavigate();

  useEffect(() => {
    if(!state){
      navigate(RoutePath.LOGIN);
    }
  },[state])

    useEffect(() => {
      const authed = !!localStorage.getItem(ACCESS_TOKEN);
      if(authed){
        navigate(RoutePath.HOME);
      }
    },[])

    useEffect(() => {
        if(!challengeRestore) {
            dispatch(initData());
        }
    },[dispatch])

  const renderPassPhrase = () => {
    const arrays = challengeRestore?.split(' ');
    return arrays?.map((phrase: string, index) => {
      return (
        <Col span={7}>
          {index + 1}. {phrase}
        </Col>
      );
    });
  };

  function downloadKeystore() {
      toast('Downloaded keystore');
      fileDownload(challengeRestore || '', 'keystore.txt');
      setIsContinue(true);
  }

  async function submitData () {
    try {
      if (!challengeRestore) {
        toast('Something is wrong');
        navigate(RoutePath.LOGIN);
      }
      const {
        address,
        city,
        email,
        mobile,
        company_name,
        country,
        postal_zip,
        username,
        password,
        first_name,
        last_name,
      } = state; // Read values passed on state
      const displayName = first_name + ' ' + last_name;
      //enable passkey
      const credential = await CreatePassKeyCredential(
        username.toLowerCase(),
        displayName.toLowerCase(),
        challenge,
        username || email
      );

      const passKeyJson = publicKeyCredentialToJSON(credential);

      if (credential) {
        dispatch(
          signUp({
            address,
            city,
            email,
            mobile,
            postal_zip,
            username,
            password,
            company_name,
            country,
            first_name, last_name,
            challenge_id: challengeId,
            credential: passKeyJson,
          }),
        )
          .unwrap()
          .then((response) => {
            toast(response.data.message);
            navigate(RoutePath.LOGIN);
          })
      } else {
        toast('Credential does not exist.')
      }
    }catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="w-full h-screen items-center flex flex-col overflow-hidden">
      <div className="flex flex-col items-center justify-center mt-20 lg:mt-0">
          <span className='text-center font-medium'>Write Down Your Recovery Phrase & <br/> Download Your Keystore File</span>
          <span className='text-center'>Please write down your recovery phrase and download <br/> your keystore file. Keep both of these secure, we cannot <br/>recover them for you.</span>
          <div className="flex bg-amber-100 items-center m-10 rounded-l p-4">
          <Row justify="space-between">{renderPassPhrase()}</Row>
        </div>
      </div>
      <button
          type="submit"
          onClick={() => downloadKeystore()}
          className={
            'px-4 py-3 rounded-md border-2 border-blue-600 w-1/4 text-blue-800'}
      >
        Download Keystore File
      </button>
        <button
            disabled={!isContinue}
            type="submit"
            onClick={() => submitData()}
            className={
                isContinue? 'px-4 py-3 rounded-md bg-blue-500 w-1/4 mt-5 text-gray-50 text-white':
                    'px-4 py-3 rounded-md bg-gray-500 w-1/4 mt-5 text-gray-50 text-white'}
        >
            Continue
        </button>

    </div>
  );
};
