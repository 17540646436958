import React from "react";
import { RoutePath } from '../../../constants/constant';

interface Props{
    label: string;
    value: string;
}
export const ItemProfile=(props: Props)=>{
    const {label, value} = props;
    return (
      <div className="mt-5">
          <div className={'flex flex-row'}>
            <span className={'font-bold mr-2'}>{label}:</span> <span>{value}</span>
          </div>
      </div>
    )
}
