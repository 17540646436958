import * as Yup from 'yup';

export interface RevenueShareDataType {
  key?: number;
  id?: string;
  licensee_email: string;
  licensee_user_name: string;
  licensee_account_number: string;
  licensee_id: string;
  licensee_fee: number;
  country_code:string;
  is_accepted: boolean;
}
export const revenueSchema = Yup.object().shape({
  licensee_user_name: Yup.string().required('Licensee Name is required'),
  licensee_fee: Yup.string().required('Percentage is required'),
  country_code: Yup.string().required('Territory is required'),
});

export const initialValues: RevenueShareDataType = {
  licensee_email: '',
  licensee_user_name: '',
  licensee_account_number: '',
  licensee_id: '',
  licensee_fee: 0,
  country_code: '',
  is_accepted: false,
};

export interface RevenueShareResponseType {
  _id: string;
  userId: string,
  licenseeId: {
    _id: string;
    userName: string;
    userEmail: string;
    userPublicAddress: string,
  };
  licenseeFee: number;
  licenseeFeeNewValue: number;
  appId: string;
  countryCode: string;
  needToBeConfirm: boolean;
  userConfirmed: boolean;
  status: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface autoCompleteUser {
  id: string;
  value: string;
  abel: string;
  userName: string;
  userEmail: string;
  userPublicAddress: string;
}
