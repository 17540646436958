import React, {StrictMode, useEffect} from 'react';
import './App.css';
import { Login } from './pages/login';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { SignUp } from './pages/sign_up';
import DefaultLayout from './layout/default-layout';
import { Profile } from './pages/profile';
import { ChangePassword } from './pages/change_password';
import {Home} from "./pages/home";
import {PassPhrase} from "./pages/passphrase";
import { Navigate, useLocation } from "react-router-dom";
import {ACCESS_TOKEN} from "./constants/constant";
import {ForgotPassword} from "./pages/forgot_password";
import { BankAccount } from './pages/bank_account';
import { Logout } from './pages/logout';
import {MySongs} from "./pages/my_songs";
import {RevenueShare} from "./pages/my_revenue_share";
import {MemberCountsHistoryDetail} from "./pages/member_counts_history_detail";
import {MemberCountsHistory} from "./pages/member_counts_history";
import {AccountingAccrual} from "./pages/accounting_accrual";
import {SongCountsDetail} from "./pages/song_count_detail_history";
import {AccrualDetail} from "./pages/accounting_accrual_detail";
import {AccrualDetailBySubscriber} from "./pages/accounting_accrual_detail_by_subscriber";


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<DefaultLayout />}>
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/edit-profile" element={<SignUp />} />
      <Route path="/bank-account" element={<BankAccount />} />
      <Route path="/passphrase" element={<PassPhrase />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/account-accrual" element={
        <RequireAuth>
          <AccountingAccrual />
        </RequireAuth>
      } />
      <Route path="/accrual-detail" element={
        <RequireAuth>
          <AccrualDetail />
        </RequireAuth>
      } />
      <Route path="/accrual-detail-by-subscriber" element={
        <RequireAuth>
          <AccrualDetailBySubscriber />
        </RequireAuth>
      } />
      <Route path="/my-songs" element={
        <RequireAuth>
          <MySongs />
        </RequireAuth>
      } />
      <Route path="/revenue-share" element={
        <RequireAuth>
          <RevenueShare />
        </RequireAuth>
      } />
      <Route path="/member-count" element={
        <RequireAuth>
          <MemberCountsHistory />
        </RequireAuth>
      } />
      <Route path="/member-count-detail" element={
        <RequireAuth>
          <MemberCountsHistoryDetail />
        </RequireAuth>
      } />
      <Route path="/song-count-detail" element={
        <RequireAuth>
          <SongCountsDetail />
        </RequireAuth>
      } />
        <Route
          path="/"
          element={
            <RequireAuth>
                <Home />
            </RequireAuth>
          }
        />
      <Route path="/profile"
         element={
           <RequireAuth>
               <Profile />
           </RequireAuth>
         }
      />
      <Route path="/change-password"
         element={
           <RequireAuth>
               <ChangePassword />
           </RequireAuth>
         }
      />
      <Route path='*'  element={ <Navigate to="/" />} />
    </Route>,
  ),
);

// @ts-ignore
function RequireAuth({ children }) {
    let location = useLocation();
    const authed = !!localStorage.getItem(ACCESS_TOKEN);
    return authed ? children : <Navigate to="/login" state={{ from: location }} replace />;
}

export const App = () => {
  return <RouterProvider router={router} />;
};
