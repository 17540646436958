import React, { useEffect, useState } from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { useAppDispatch} from '../../redux/store';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {DatePicker,  Table} from 'antd';
import {ColumnsType} from "antd/es/table";
import {getMemberDetailCount} from "../../redux/report/reportThunk";
import {MemberCountHistoryDetailType} from "./memberCountHistoryDetail.type";
import {RoutePath} from "../../constants/constant";

export const MemberCountsHistoryDetail: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [memberCountDetail, setMemberCountDetail] = useState([]);
  const location = useLocation().state;
  if(!location){
    navigate('/')
  }
  let { memberCountData } = location;
  console.log(memberCountData)
  const getMemberCountData = () => {
    if(!memberCountData) return;
    dispatch(getMemberDetailCount({memberCountId: memberCountData._id}))
      .unwrap()
      .then((response) => {
        console.log(response);
        setMemberCountDetail(response.data.data);
      });
  };
  useEffect(() => {
    void getMemberCountData();
  }, [memberCountData]);

  const columns: ColumnsType<MemberCountHistoryDetailType> = [
    {
      title: 'TrackId',
      dataIndex: 'trackId',
      key: 'TrackId',
      render: (_, record) => (
        <span>{record.trackId}</span>
      ),
    },
    {
      title: 'Song Title',
      dataIndex: 'songTitle',
      key: 'songTitle',
      render: (_, record) => {
        return <span>{record?.songRefer.songTitle}</span>
      },
    },
    // {
    //   title: 'Artist',
    //   dataIndex: 'artist',
    //   key: 'artist',
    //   render: (_, record) => {
    //     return <span>{record?.artist}</span>
    //   },
    // },
    {
      title: 'Tracks count',
      dataIndex: 'playCount',
      key: 'playCount',
    },
    {
      title: '% (Tracks count)',
      key: 'percent',
      dataIndex: 'percent',
      render: (_, record) => (
        <span>{((record.playCount/memberCountData.playCount) *100).toFixed(1)}%</span>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 100,
      render: (_, record) => (
        <button
          type="submit"
          onClick={() => navigate(RoutePath.SONG_COUNTS_DETAIL_HISTORY, {
            state: {
              record: record
            }
          })}
          className={
            'py-2 px-4 rounded-md bg-blue-600 border-2 border-blue-600 text-white'
          }
        >
          View
        </button>
      ),
    }
  ]

  return (
    <div className="w-4/5 h-screen mt-10 ml-5 flex flex-col overflow-hidden">
      <div className="flex flex-row my-10 lg:mt-0">
        <div className="flex flex-row w-1/2">
          <div className="flex flex-row">
            Account Name: {memberCountData.userRefer.userName}
            <br/>
            Account No: {memberCountData.userRefer.userPublicAddress}
          </div>
        </div>
      </div>
      <Table columns={columns} dataSource={memberCountDetail} />
    </div>
  );
};
