import React, { useEffect, useState } from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { useAppDispatch} from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import { DatePicker,  Table} from 'antd';
import {ColumnsType} from "antd/es/table";
import {getMemberCount} from "../../redux/report/reportThunk";
import {MemberCountHistoryType} from "./memberCountHistory.type";
import {DATE_FORMAT, RoutePath} from "../../constants/constant";
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;

export const MemberCountsHistory: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [memberCount, setMemberCount] = useState([]);
  const [startDate, setStartDate] = useState(dayjs().startOf("month"));
  const [endDate, setEndDate] = useState(dayjs().add(1, "month"));

  const columns: ColumnsType<MemberCountHistoryType> = [
    {
      title: 'Member Name',
      dataIndex: 'userRefer',
      key: 'userRefer',
      render: (_, record) => (
        <span>{record.userRefer?.userName}</span>
      ),
    },
    {
      title: 'Play Count',
      dataIndex: 'playCount',
      render: (_, record) => (
        <span>{record.playCount}</span>
      ),
    },
    {
      title: 'Amount(EBTA)',
      dataIndex: 'totalCount',
      key: 'totalCount',
      render: (_, record) => (
        <span>{(record.totalAmount/1000).toFixed(0)}</span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'isPaid',
      key: 'isPaid',
      render: (_, record) => (
        <span>{record.isPaid? 'Paid' :'Confirmed'}</span>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      render: (_, record) => (
        <button
          type="submit"
          onClick={() => navigate(RoutePath.MEMBER_DETAIL_COUNTS, {
            state:{
              memberCountData: record
            }
          })}
          className={
            'py-2 px-4 rounded-md bg-blue-600 border-2 border-blue-600 text-white'
          }
        >
          View
        </button>
      ),
    },
  ]

  const getMemberCountHistoryData = () => {
    dispatch(getMemberCount({startDate: startDate.format(DATE_FORMAT), endDate: endDate.format(DATE_FORMAT)}))
      .unwrap()
      .then((response) => {
        setMemberCount(response.data.data);
      });
  };
  useEffect(() => {
    void getMemberCountHistoryData();
  }, []);


  return (
    <div className="w-4/5 h-screen mt-10 ml-5 flex flex-col overflow-hidden">
      <div className="flex flex-row my-10 lg:mt-0">
        <div className="flex flex-row w-2/3">
          <RangePicker picker="month" value={[startDate, endDate]} onCalendarChange={(val) => {
            console.log(val);
            if(val && val.length > 1) {
              if(val[0]){
                setStartDate(val[0])
              }
              if(val[1]){
                setEndDate(val[1])
              }
            }
          }}/>
          <div className="flex flex-col ml-10">
            <button
              type="submit"
              onClick={() => getMemberCountHistoryData()}
              className="items-end px-5 py-2 rounded-md border-2 border-blue-600 text-blue-800"
            >
            <span className="">
              Search
            </span>
            </button>
          </div>
        </div>
      </div>
      <Table columns={columns} dataSource={memberCount} />
    </div>
  );
};
