export const API = {
  POST: 'post',
  GET: 'get',
  PUT: 'put',
  BASE_URL: process.env.REACT_APP_BASE_URL || '',
  PREFIX: 'api/v1/',
  SIGN_IN: 'account/signin',
  VALIDATE_NEW_USER_INFO: 'account/signup-check',

  VALIDATE_PASSKEY: 'account/validate-passkey',
  CHANGE_PASSWORD: 'account/change-password',
  RESET_PASSWORD: 'account/reset-password',
  FORGOT_PASSWORD: 'account/forgot-password',
  UPDATE_PROFILE: 'account/update-profile',
  GET_PROFILE: 'account/get-profile',
  UPDATE_BANK_ACCOUNT: 'account/update-bank-account',
  BALANCE: 'account/balance',
  SIGN_UP: 'account/signup',
  LOGOUT: 'account/logout',
  GET_DATA_SIGN_UP: 'account/new',
  POST_VALIDATE_PASS_KEY: 'account/validate-passkey',

  GET_MY_SONGS: 'account/songs/my-songs/',

  GET_USER_AUTO_COMPLETED: 'account/autoComplete',
  VALIDATE_MEMBER: 'account/autoComplete',

  POST_ACCEPT_OR_DECLINE_REVENUE_SHARE: 'account/confirm-revenue-share',
  CREATE_OR_UPDATE_REVENUE_SHARE: 'account/revenue-share/createOrUpdate',
  GET_ALL_REVENUE_SHARE: 'account/revenue-share',

  GET_MEMBER_COUNT: 'report/member-counts-history',
  GET_MEMBER_DETAIL_COUNT: 'report/member-counts-detail-history',
  GET_SONG_COUNT_DETAIL_HISTORY: 'report/song-counts-detail-history',

  GET_ACCOUNT_ACCRUAL: 'report/account-accrual',
  GET_ACCRUAL_DETAIL: 'report/account-accrual-detail',
  PLAY_TIME_DETAIL_BY_ACCRUAL_AND_SUBSCRIBER: 'report/play-time-detail-by-accrual-detail-subscriber',
};
