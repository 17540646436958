import React, { useEffect, useState } from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { useAppDispatch } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import {AutoComplete, Table} from 'antd';
import { ColumnsType } from 'antd/es/table';
import {getUserAutoComplete} from "../../redux/home/homeThunk";
import {getMySongs} from "../../redux/song/songThunk";
import {countryCode, SongType} from "./song.type";
import {toast} from "react-toastify";
import clsx from "clsx";
import {autoCompleteUser} from "../my_revenue_share/revenue.type";
import { DownOutlined } from '@ant-design/icons';

export const MySongs: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [mysongs, setMySongs] = useState([])
  const [url, setUrl] = useState('')

  useEffect(() => {
    void getSongByPublisherData();
  }, [dispatch]);

  const getSongByPublisherData = () => {
    dispatch(getMySongs())
      .unwrap()
      .then((response) => {
        setMySongs(response.data.data);
        setUrl(response.data.url);
      });
  };

  const columns: ColumnsType<SongType> = [
    {
      title: 'Track Id',
      dataIndex: 'trackId',
      key: 'trackId',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Song Title',
      dataIndex: 'songTitle',
      key: 'songTitle',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Artist Name',
      dataIndex: 'artist',
      key: 'artist',
      render: (text) => <span>{text}</span>,
    },
    // {
    //   title: 'Time',
    //   dataIndex: 'time',
    //   key: 'time',
    //   render: (text) => <span>{text}</span>,
    // },
    // {
    //   title: 'Register Address',
    //   key: 'register_address',
    //   dataIndex: 'register_address',
    //   render: (text) => <a>{text}</a>,
    // },
    // {
    //   title: 'Verification',
    //   key: 'verified',
    //   dataIndex: 'verified',
    //   render: (_, record) => <span className={'text-blue-700 font-bold'}>{record.verified? 'VERIFIED':'NOT VERIFIED'}</span>,
    // },
    {
      title: 'Action',
      key: 'action',
      width: 120,
      render: (_, record) => (
        <div className={'flex flex-row items-center'}>
          <a
            type="submit"
            href={url + record.trackId.toString()}
            target="_blank"
            className={
              'py-2 px-4 mr-3 rounded-md bg-blue-600 border-2 border-blue-600 text-white'
            }
          >
            Detail
          </a>
          {/*<a*/}
          {/*  onClick={() => {*/}
          {/*    onPlayRecord(record)*/}
          {/*  }}*/}
          {/*  type="submit"*/}
          {/*  href='#'*/}
          {/*  className={*/}
          {/*    'py-2 px-4 mr-3 rounded-md bg-blue-600 border-2 border-blue-600 text-white'*/}
          {/*  }*/}
          {/*>*/}
          {/*  Play*/}
          {/*</a>*/}
        </div>
      ),
    },
  ];

  return (
    <div className="w-4/5 h-screen mt-10 ml-5 flex flex-col overflow-hidden">
      <div className="flex flex-row w-1/2 ml-0">
        <h1 className="pb-10 text-2xl text-blue-900">SONGS LIBRARY</h1>
      </div>
      <Table columns={columns} dataSource={mysongs}/>
    </div>
  );
};
