//SMS login
export const INIT_DATA = 'INIT_DATA';
export const BALANCE = 'BALANCE';
export const SIGN_IN = 'SIGN_IN';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SIGN_UP = 'SIGN_UP';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_BANK_ACCOUNT = 'UPDATE_BANK_ACCOUNT';
export const LOGOUT = 'LOGOUT';
export const CREATE_OR_UPDATE_LICENSE = 'CREATE_OR_UPDATE_LICENSE';

export const USER_AUTO_COMPLETE = 'USER_AUTO_COMPLETE';
export const VALIDATE_MEMBER = 'VALIDATE_MEMBER';
export const VALIDATE_PASS_KEY = 'VALIDATE_PASS_KEY';
export const GET_SONGS_BY_PUBLISHER = 'GET_SONGS_BY_PUBLISHER';
export const GET_MEMBER_COUNT = 'GET_MEMBER_COUNT';
export const GET_MEMBER_DETAIL_COUNT = 'GET_MEMBER_DETAIL_COUNT';
export const GET_SONG_COUNT_DETAIL_HISTORY = 'GET_SONG_COUNT_DETAIL_HISTORY';
export const GET_JOBS_POST = 'GET_JOBS_POST';
export const GET_JOBS_POST_LOG = 'GET_JOBS_POST_LOG';
export const GET_JOBS_POST_LOG_REALTIME = 'GET_JOBS_POST_LOG_REALTIME';
export const CONFIRM_JOB_POST = 'CONFIRM_JOB_POST';
export const RE_RUN_JOB_POST = 'RE_RUN_JOB_POST';
export const POST_ACTION = 'POST_ACTION';
export const SAVE_SETING = 'SAVE_SETTING';
export const GET_SETTING = 'GET_SETTING';
export const CREATE_OR_UPDATE_REVENUE_SHARE = 'CREATE_OR_UPDATE_REVENUE_SHARE';
export const GET_ALL_MY_REVENUE_SHARE = 'GET_ALL_MY_REVENUE_SHARE';
export const CONFIRM_REVENUE_SHARE = 'CONFIRM_REVENUE_SHARE';
export const GET_MEMBER_ACCRUAL = 'GET_MEMBER_ACCRUAL';
export const GET_ACCRUAL_DETAIL = 'GET_ACCRUAL_DETAIL';
export const GET_PLAY_TIME_DETAIL_BY_ACCRUAL_AND_SUBSCRIBER = 'GET_PLAY_TIME_DETAIL_BY_ACCRUAL_AND_SUBSCRIBER';


