import { createSlice } from '@reduxjs/toolkit';
import {initData, logoutUser, signIn, validatePassKey} from './authThunk';
import {ACCESS_TOKEN, USER_INFO} from '../../constants/constant';
import base64url from "../../utils/passkey/authenticate/base64url-arraybuffer";

export interface AuthState {
  token?: string;
  challenge?: string;
  challengeRestore?: string;
  challengeId?: string;
  errorMessage?: string;
}
const initialState: AuthState = {
  token:
    sessionStorage.getItem(ACCESS_TOKEN) ||
    localStorage.getItem(ACCESS_TOKEN) ||
    '',
};
export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(validatePassKey.pending, () => {});
    builder.addCase(validatePassKey.fulfilled, (state, action) => {
      if(action.payload.data.data.jwtToken) {
        state.token = action.payload.data.data.jwtToken;
        localStorage.setItem(ACCESS_TOKEN, action.payload.data.data.jwtToken);
        localStorage.setItem(USER_INFO, JSON.stringify(action.payload.data.data.user));
      }
    });
    builder.addCase(initData.fulfilled, (state, action) => {
      const decoded = base64url.toString(base64url.decode(action.payload.data.data.challenge));
      console.log(decoded);
      state.challengeRestore = decoded;
      state.challenge = action.payload.data.data.challenge;
      state.challengeId = action.payload.data.data.id;
    });
    builder.addCase(validatePassKey.rejected, (state, action) => {
      console.log(action.payload);
    });
    builder.addCase(signIn.fulfilled, (state, action) => {
      if(action.payload.data.data.jwtToken) {
        state.token = action.payload.data.data.jwtToken;
        localStorage.setItem(ACCESS_TOKEN, action.payload.data.data.jwtToken);
        localStorage.setItem(USER_INFO, JSON.stringify(action.payload.data.data.user));
      }
    });
    builder.addCase(logoutUser.fulfilled, (state, action) => {
      state.token = '';
      localStorage.setItem(ACCESS_TOKEN, '');
      localStorage.setItem(USER_INFO, '');
    })
  },
});
//Actions
export const authActions = authSlice.actions;

//Reducer
const authReducer = authSlice.reducer;
export default authReducer;
