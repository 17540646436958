import * as Yup from 'yup';
import axios from "axios";
import {API} from "../../apis/api";

export interface SignUpType {
  role?: number;
  username?: string;
  first_name?: string;
  last_name?: string;
  email: string;
  national_id?: string;
  company_name?: string;
  address?: string;
  city?: string;
  country?: string;
  postal_zip?: string;
  mobile?: string;
  password?: string;
  confirm_password?: string;
  challenge_id?: string;
  account_no?: string;
  account_name?: string;
  bank_name?: string;
  bank_address?: string;
  swift_code?: string;
  credential?: any;
}


export interface BankAccountType {
  account_no?: string;
  account_name?: string;
  bank_name?: string;
  bank_address?: string;
  swift_code?: string;
}

export const signupSchema = Yup.object().shape({
  username: Yup.string().required('User is required').test('Unique UserName','UserName already in use',
    function(value){return new Promise((resolve, reject) => {
      axios.post(`${API.BASE_URL}${API.PREFIX}${API.VALIDATE_NEW_USER_INFO}`, {'userName': value})
        .then(res => {if(res.data.code === 512){resolve(false)} resolve(true)})
    })}
  ),
  email: Yup.string().email('Invalid email').required('Email is required').test('Unique Email','Email already in use',
    function(value){return new Promise((resolve, reject) => {
      axios.post(`${API.BASE_URL}${API.PREFIX}${API.VALIDATE_NEW_USER_INFO}`, {'email': value})
        .then(res => {if(res.data.code === 512){resolve(false)} resolve(true)})
    })}
  ),
  password: Yup.string()
    .min(6, 'At least 6 characters')
    .required('Password is required'),
  confirm_password: Yup.string()
    .min(6, 'At least 6 characters')
    .required('Confirm password is required')
    .oneOf([Yup.ref('password')], 'Passwords do not match'),
});

export const updateProfileSchema = Yup.object().shape({

});
