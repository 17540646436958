export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const USER_INFO = 'USER_INFO';
export const BANK_INFO = 'BANK_INFO';
export const ResponseStatus = {
  SUCCESS: 200,
  UNAUTHORIZED: 401,
};
export const RoutePath = {
  HOME: '/',
  LOGIN: '/login',
  SIGN_UP: '/sign-up',
  PASS_PHRASE: '/passphrase',
  EDIT_PROFILE: '/edit-profile',
  FORGOT_PASSWORD: '/forgot-password',
  BANK_ACCOUNT: '/bank-account',
  PROFILE: '/profile',
  MEMBER_DETAIL_COUNTS: '/member-count-detail',
  SONG_COUNTS_DETAIL_HISTORY: '/song-count-detail/',
  ACCRUAL_DETAIL_BY_SUBSCRIBER: '/accrual-detail-by-subscriber/',
  ACCRUAL_DETAIL: '/accrual-detail',
  JOBS_POST: '/job-post/',
  JOBS_POST_VIEW_LOG: '/job-view-log/',
};

export const DATE_FORMAT = 'YYYY-MM-DD';
