import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { API } from './api';
import { toast } from 'react-toastify';
import {ACCESS_TOKEN} from "../constants/constant";

interface ConfigType {
  headerContentType: string;
}

const axiosClient = axios.create({
  baseURL: API.BASE_URL + API.PREFIX,
});

// Add a request interceptor
axiosClient.interceptors.request.use(
  // eslint-disable-next-line @typescript-eslint/require-await
  async function (config: AxiosRequestConfig<ConfigType>) {
    // Do something before request is sent
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    const headers = {
      'Content-Type': config?.data?.headerContentType || 'application/json',
    };
    config.headers = {
      ...headers,
      Accept: 'application/json, text/plain, */*',
      Authorization: `Bearer ${accessToken ? accessToken : ''}`,
    };
    return config;
  },
  async function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response: AxiosResponse) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async function (error: AxiosError) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // if (error?.response?.status === ResponseStatus.UNAUTHORIZED) {
    //
    // }
    toast.error(error.response?.data.message);
    return Promise.reject(error);
  },
);

export default axiosClient;
