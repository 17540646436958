import React, { useEffect, useState } from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { useAppDispatch } from '../../redux/store';
import {useLocation, useNavigate} from 'react-router-dom';
import {DatePicker, Space, Table, Tag} from 'antd';
import {ColumnsType} from "antd/es/table";
import {RoutePath, USER_INFO} from "../../constants/constant";
import {getPlayTimeDetailByAccrualIdAndSubscriber} from "../../redux/report/reportThunk";
import {SongCountType} from "../song_count_detail_history/SongCountType";
import {PlayTimeDetailType} from "./PlayTimeDetail.type";
import {AccrualDetailType} from "../accounting_accrual_detail/accrualDetail.type";

export const AccrualDetailBySubscriber: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [playTimeDetail, setPlayTimeDetail] = useState([]);
  let userInfo = localStorage.getItem(USER_INFO)? JSON.parse(localStorage.getItem(USER_INFO) || '{}') : null;
  const location = useLocation().state;
  if(!location || !userInfo){
    navigate('/')
  }
  let { accrualDetail, accrual } = location;
  if(!accrualDetail){
    navigate('/')
  }

  const getMemberCountData = () => {
    dispatch(getPlayTimeDetailByAccrualIdAndSubscriber({accrualId: accrual._id, subscriberId: accrualDetail.fromSubscriberId}))
      .unwrap()
      .then((response) => {
        setPlayTimeDetail(response.data.data);
      });
  };
  useEffect(() => {
    void getMemberCountData();
  }, [accrualDetail]);

  const columns: ColumnsType<PlayTimeDetailType> = [
    {
      title: 'Track ID',
      dataIndex: 'trackId',
      key: 'trackId',
      render: (_, record) => (
        <span>{record.playTimeId.songRefer.trackId}</span>
      ),
    },
    {
      title: 'Song Title',
      dataIndex: 'songTitle',
      key: 'songTitle',
      render: (_, record) => (
        <span>{record.playTimeId.songRefer.songTitle}</span>
      ),
    },
    {
      title: 'Date Time',
      dataIndex: 'datetime',
      key: 'datetime',
      render: (_, record) => (
        <span>{record.createdAt.toString()}</span>
      ),
    },
    {
      title: 'Transaction',
      dataIndex: 'playCount',
      key: 'playCount',
      render: (_, record) => (
        <a target="_blank" href={`${process.env.REACT_APP_ALGORAND_EXPOLER_URL}/tx/${record.txid}`}>{record.txid}</a>
      ),
    },
  ]

  const columnsFormular: ColumnsType<AccrualDetailType> = [
    {
      title: 'Total Member Count (X)',
      dataIndex: 'totalMemberCount',
      key: 'totalMemberCount',
      render: (_, record) => (
        <span>{record.totalMemberCount}</span>
      ),
    },
    {
      title: 'Total Subscriber Counts (Y)',
      dataIndex: 'totalSubscriberCounts',
      key: 'totalSubscriberCounts',
      render: (_, record) => (
        <span>{record.totalSubscriberCounts}</span>
      ),
    },
    {
      title: 'Amount - EBTA (X/Y * monthly)',
      dataIndex: 'amount',
      key: 'amount',
      render: (_, record) => (
        <span>{record.amount > 0? (record.amount/1000).toFixed(2) :''}</span>
      ),
    },
  ]
  return (
    <div className="w-4/5 h-screen mt-10 ml-5 flex flex-col overflow-hidden">
      <div className={'flex flex-col mb-5 text-blue-800'}>
        <label>Accrual Transaction: <a target="_blank" href={`${process.env.REACT_APP_ALGORAND_EXPOLER_URL}/tx/${accrual.txId}`}>{accrual.txId}</a></label>
        <label>Amount: {accrualDetail.amount > 0? (accrualDetail.amount/1000).toFixed(2) :''} EBTA</label>
        <label>SubscriberId: {accrualDetail.fromSubscriberId}</label>
        <br/>
      </div>
      <Table columns={columns} dataSource={playTimeDetail} />
      <div className={'flex flex-col mb-5 text-black-800'}>
        <label>Calculation</label>
      </div>
      <Table pagination={false} columns={columnsFormular} dataSource={[accrualDetail]} />
    </div>
  );
};
