import { createAsyncThunk } from '@reduxjs/toolkit';
import * as types from '../types';
import axiosClient from '../../apis/axiosClient';
import store from '../store';
import { appActions } from '../app/appSlice';
import { API } from '../../apis/api';
import {
  GET_ACCRUAL_DETAIL,
  GET_JOBS_POST,
  GET_JOBS_POST_LOG, GET_JOBS_POST_LOG_REALTIME, GET_MEMBER_ACCRUAL,
  GET_MEMBER_COUNT,
  GET_MEMBER_DETAIL_COUNT, GET_PLAY_TIME_DETAIL_BY_ACCRUAL_AND_SUBSCRIBER,
  POST_ACTION,
} from "../types";

export const getMemberCount = createAsyncThunk(
  types.GET_MEMBER_COUNT,
  async (request: {startDate: string, endDate: string }) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.GET_MEMBER_COUNT, request)
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
          console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);

export const getMemberDetailCount = createAsyncThunk(
  types.GET_MEMBER_DETAIL_COUNT,
  async (request: {memberCountId: string}) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.GET_MEMBER_DETAIL_COUNT, request)
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);

export const getSongCountDetailHistory = createAsyncThunk(
  types.GET_SONG_COUNT_DETAIL_HISTORY,
  async (request: {memberCountDetailId: string}) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.GET_SONG_COUNT_DETAIL_HISTORY, request)
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);

export const getMemberAccrualData = createAsyncThunk(
  types.GET_MEMBER_ACCRUAL,
  async (request: {}) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.GET_ACCOUNT_ACCRUAL, request)
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);

export const getAccrualDetailData = createAsyncThunk(
  types.GET_ACCRUAL_DETAIL,
  async (request: {accrualId: string}) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.GET_ACCRUAL_DETAIL, request)
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);

export const getPlayTimeDetailByAccrualIdAndSubscriber = createAsyncThunk(
  types.GET_PLAY_TIME_DETAIL_BY_ACCRUAL_AND_SUBSCRIBER,
  async (request: {accrualId: string, subscriberId: string}) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.PLAY_TIME_DETAIL_BY_ACCRUAL_AND_SUBSCRIBER, request)
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);





