import React, { useEffect, useState } from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { useAppDispatch} from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import {ACCESS_TOKEN, RoutePath, USER_INFO} from "../../constants/constant";


export const Logout: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.setItem(ACCESS_TOKEN, '');
    localStorage.setItem(USER_INFO, '');
    navigate(RoutePath.LOGIN);
  }, [dispatch]);

  return (
    <div className="w-full md:h-screen"></div>
  );
};
