import React, { useEffect, useState } from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { BANK_INFO, RoutePath, USER_INFO } from '../../constants/constant';
import { useNavigate } from 'react-router-dom';
import { ItemProfile } from './components/ItemProfile';
import { getBalance } from '../../redux/home/homeThunk';
import { useAppDispatch } from '../../redux/store';
import { getUserProfile } from '../../redux/auth/authThunk';

export const Profile: React.FC = () => {
  const dispatch = useAppDispatch();
  const [userInfo, setUserInfo] = useState<any>(JSON.parse(localStorage.getItem(USER_INFO) || '{}') );
  const [bankInfo, setBankInfo] = useState<any>(JSON.parse(localStorage.getItem(BANK_INFO) || '{}'));
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getUserProfile())
      .unwrap()
      .then((response) => {
        localStorage.setItem(USER_INFO, JSON.stringify(response.data.data.user));
        localStorage.setItem(BANK_INFO, JSON.stringify(response.data.data.bankAccount));
        setUserInfo(response.data.data.user);
        setBankInfo(response.data.data.bankAccount);
      });
  }, []);
  return (
    <div className="mx-auto rounded-xl mt-5 flex flex-row p-5">
      <div className="items-center w-1/2 bg-white p-5 rounded-xl mr-5">
        <span className="text-center uppercase text-2xl font-semibold block">
          MY ACCOUNT
        </span>
        {userInfo &&
        <div className="mx-auto p-5">
          <ItemProfile label='User ID' value={userInfo?.userName} />
          <ItemProfile label='Email' value={userInfo?.userEmail} />
          <ItemProfile label='First Name' value={userInfo?.userFirstName} />
          <ItemProfile label='Last Name' value={userInfo?.userLastName} />
          <ItemProfile label='Company name' value={userInfo?.userCompanyName} />
          <ItemProfile label='Address' value={userInfo?.userAddress} />
          <ItemProfile label='City' value={userInfo?.userCity} />
          <ItemProfile label='Postal Zip code' value={userInfo?.userPostalZip} />
          <ItemProfile label='Country' value={userInfo?.userCountry} />
          <ItemProfile label='Mobile' value={userInfo?.userPhone} />
          <div className="mt-12">
            <button
              type="submit"
              onClick={() => navigate(RoutePath.EDIT_PROFILE)}
              className='px-20 py-3 rounded-md w-full text-gray-50 bg-blue-400 mb-8'
            >
              Update Profile
            </button>
          </div>
        </div>
        }
      </div>

      <div className="items-center w-1/2 bg-white p-5 rounded-xl ">
        <span className="text-center uppercase text-2xl font-semibold block">
          MY BANK ACCOUNT
        </span>
        {bankInfo &&
        <div className="mx-auto p-5">
          <ItemProfile label='Account No' value={bankInfo.accountNumber} />
          <ItemProfile label='Account Name' value={bankInfo.accountName} />
          <ItemProfile label='Bank Name' value={bankInfo.bankName} />
          <ItemProfile label='Bank Address' value={bankInfo.bankAddress} />
          <ItemProfile label='Swift Code' value={bankInfo.bankSwiftCode} />
          <div className="mt-12">
            <button
              type="submit"
              onClick={() => navigate(RoutePath.BANK_ACCOUNT)}
              className='px-20 py-3 rounded-md w-full text-gray-50 bg-blue-400 mb-8'
            >
              Update Bank Account
            </button>
          </div>
        </div>
        }
      </div>

    </div>
  );
};
