import { createAsyncThunk } from '@reduxjs/toolkit';
import * as types from '../types';
import axiosClient from '../../apis/axiosClient';
import store from '../store';
import { appActions } from '../app/appSlice';
import { API } from '../../apis/api';
import { SignUpType } from '../../pages/sign_up/sign_up.type';
import {toast} from "react-toastify";
import { GET_PROFILE, UPDATE_BANK_ACCOUNT } from '../types';

export const initData = createAsyncThunk(
    types.INIT_DATA,
    async () => {
        store.dispatch(appActions.showLoading());
        return axiosClient
            .get(API.GET_DATA_SIGN_UP)
            .then((response) => {
                store.dispatch(appActions.hideLoading());
                return response;
            })
            .catch((err) => {
                store.dispatch(appActions.hideLoading());
                throw err;
            });
    },
);

export const signIn = createAsyncThunk(
  types.SIGN_IN,
  async (request: { username: string; password: string }) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.SIGN_IN, {
        username: request.username,
        password: request.password,
      })
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
          console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);

export const validatePassKey = createAsyncThunk(
  types.VALIDATE_PASS_KEY,
  async (request: { credential: any, user_name: string, user_id: string }) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.VALIDATE_PASSKEY, {
        credential: request.credential,
        user_name: request.user_name,
        user_id: request.user_id
      })
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);


export const forgotPassword = createAsyncThunk(
    types.FORGOT_PASSWORD,
    async (request: { email: string }) => {
        store.dispatch(appActions.showLoading());
        return axiosClient
            .post(API.FORGOT_PASSWORD, {
                email: request.email,
            })
            .then((response) => {
                store.dispatch(appActions.hideLoading());
                return response;
            })
            .catch((err) => {
                store.dispatch(appActions.hideLoading());
                throw err;
            });
    },
);
export const resetPassword = createAsyncThunk(
    types.RESET_PASSWORD,
    async (request: { password: string }) => {
        store.dispatch(appActions.showLoading());
        return axiosClient
            .post(API.RESET_PASSWORD, {
                email: request.password,
            })
            .then((response) => {
                store.dispatch(appActions.hideLoading());
                return response;
            })
            .catch((err) => {
                store.dispatch(appActions.hideLoading());
                throw err;
            });
    },
);

export const changePassword = createAsyncThunk(
  types.CHANGE_PASSWORD,
  async (request: {
    old_password: string;
    new_password: string;
    confirm_new_password: string;
  }) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.CHANGE_PASSWORD, {
        old_password: request.old_password,
        new_password: request.new_password,
        confirm_new_password: request.confirm_new_password,
      })
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);

export const signUp = createAsyncThunk(
  types.SIGN_UP,
  async (request: SignUpType) => {
    store.dispatch(appActions.showLoading());
    console.log(request);
    return axiosClient
      .post(API.SIGN_UP, {
        role: request.role,
        username: request.username,
          first_name: request.first_name,
          last_name: request.last_name,
        email: request.email,
        password: request.password,
        country: request.country,
        city: request.city,
        address: request.address,
        mobile: request.mobile,
        postal_zip: request.postal_zip,
        company_name: request.company_name,
        challenge_id: request.challenge_id,
        credential: request.credential,
      })
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);


export const updateProfile = createAsyncThunk(
    types.UPDATE_PROFILE,
    async (request: SignUpType) => {
        store.dispatch(appActions.showLoading());
        return axiosClient
            .post(API.UPDATE_PROFILE, {
                email: request.email,
                country: request.country,
                city: request.city,
                address: request.address,
                mobile: request.mobile,
                postal_zip: request.postal_zip,
                company_name: request.company_name,
                first_name: request.first_name,
                last_name: request.last_name
            })
            .then((response) => {
                store.dispatch(appActions.hideLoading());
                return response;
            })
            .catch((err) => {
                store.dispatch(appActions.hideLoading());
                throw err;
            });
    },
);

export const updateBankAccount = createAsyncThunk(
  types.UPDATE_BANK_ACCOUNT,
  async (request: {
    account_no?: string,
    account_name?: string,
    bank_name?: string,
    bank_address?: string,
    swift_code?: string,
  }) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.UPDATE_BANK_ACCOUNT, {
        account_no: request.account_no,
        account_name: request.account_name,
        bank_name: request.bank_name,
        bank_address: request.bank_address,
        swift_code: request.swift_code,
      })
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);

export const getUserProfile = createAsyncThunk(
  types.GET_PROFILE,
  async () => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .get(API.GET_PROFILE)
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);

export const logoutUser = createAsyncThunk(types.LOGOUT, async () => {
  store.dispatch(appActions.showLoading());
  return axiosClient
    .get(API.LOGOUT)
    .then((response) => {
      store.dispatch(appActions.hideLoading());
      return response;
    })
    .catch((err) => {
      store.dispatch(appActions.hideLoading());
      throw err;
    });
});



