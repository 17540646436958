import * as Yup from 'yup';

export interface BankAccountType {
  account_no?: string;
  account_name?: string;
  bank_name?: string;
  bank_address?: string;
  swift_code?: string;
}

export const bankSchema = Yup.object().shape({
  account_no: Yup.string().required('Account No is required'),
  account_name: Yup.string().required('Account Name is required'),
  bank_name: Yup.string()
    .required('Bank name is required'),
  bank_address: Yup.string()
    .required('Bank address is required'),
  swift_code: Yup.string()
    .required('Swift code is required')
});

export const initialValues: BankAccountType = {
  account_no: '',
  account_name: '',
  bank_name: '',
  bank_address: '',
  swift_code: '',
};
