import { createAsyncThunk } from '@reduxjs/toolkit';
import * as types from '../types';
import axiosClient from '../../apis/axiosClient';
import store from '../store';
import { appActions } from '../app/appSlice';
import { API } from '../../apis/api';

export const getMySongs = createAsyncThunk(
  types.GET_SONGS_BY_PUBLISHER,
  async () => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .get(API.GET_MY_SONGS)
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
          console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);


