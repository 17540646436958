import { createAsyncThunk } from '@reduxjs/toolkit';
import * as types from '../types';
import axiosClient from '../../apis/axiosClient';
import store from '../store';
import { appActions } from '../app/appSlice';
import { API } from '../../apis/api';
import {VALIDATE_MEMBER} from "../types";

export const getBalance = createAsyncThunk(
  types.BALANCE,
  async () => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .get(API.BALANCE)
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);

export const getUserAutoComplete = createAsyncThunk(
  types.USER_AUTO_COMPLETE,
  async (request:{keyword: string}) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.GET_USER_AUTO_COMPLETED, { keyword: request.keyword})
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);

export const getMemberByName = createAsyncThunk(
  types.VALIDATE_MEMBER,
  async (request:{keyword: string}) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.VALIDATE_MEMBER, { keyword: request.keyword})
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);
